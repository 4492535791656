import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import APCategoryBtns from '../../components/preowned/APCategoryBtns'

// markup
const APRoyalOakMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryAPRoyalOakMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Audemars Piguet" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Audemars Piguet Royal Oak Watches for Sale'}
      canonical={'/fine-watches/audemars-piguet/audemars-piguet-royal-oak/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Audemars Piguet Royal Oak watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/audemars-piguet/audemars-piguet-royal-oak/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-audemars-piguet-royal-oak-header.png"
                alt="Pre-Owned Certified Used Audemars Piguet Royal Oak Watches Header"
                aria-label="Used Audemars Piguet Royal Oak Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Audemars Piguet Royal Oak Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE AUDEMARS PIGUET ROYAL OAK
              WATCHES AT GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED AUDEMARS PIGUET WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              Unveiled in 1972, the Royal Oak has grown to become Audemars Piguet’s most famous
              watch model and pre-owned Royal Oak watches are some of the most collectible and
              expensive timepieces on the secondary market.
              <br />
              <br />
              While the popularity of used AP Royal Oaks can’t be denied today, the watch was not an
              immediate hit. Not only was the Royal Oak’s unique design — characterized by an
              octagonal bezel punctuated by screws, a hobnail textured dial, and an integrated
              bracelet — polarizing but the hefty price tag attached to this all-steel
              non-complicated watch also sent shockwaves through the market.
              <br />
              <br />
              With the debut of the Royal Oak, Audemars Piguet essentially created a new timepiece
              genre: the luxury sports watch. It didn’t take long for other top watch brands to
              follow suit and similarly styled watches, such as the Patek Philippe Nautilus,
              Vacheron Constantin 222, and Girard-Perregaux Laureato were also launched in the
              1970s.
              <br />
              <br />
              <b>Buying and Selling Pre-Owned Used Audemars Piguet Royal Oak Watches</b>
              <br />
              <br />
              The RO collection has expanded greatly since its inception to include plenty of other
              materials, sizes, and even some complications.
              <br />
              <br />
              Popular Pre-Owned Used AP Royal Oak Watch References and Models:
              <br />
              <ul>
                <li>Pre-Owned Royal Oak “Jumbo” references: 5402, 14802, 15002, 15202, 16202</li>
                <li>
                  Pre-Owned Royal Oak Self-Winding references: 15300, 15400, 15450, 15500, 15550
                </li>
                <li>Pre-Owned Royal Oak 36mm references: 4100, 14332, 14486, 14700, 14790</li>
                <li>
                  Pre-Owned Royal Oak Chronograph, Royal Oak Perpetual Calendar, Royal Oak
                  Tourbillon, Royal Oak Concept
                </li>
              </ul>
              <br />
              <br />
              This immense variety can make buying and selling pre-owned used Audemars Piguet Royal
              Oak watches somewhat challenging for newer collectors. Lucky for you, Gray &amp; Sons
              are experts in secondhand Royal Oak watches with over four decades of experience
              buying and selling them.
              <br />
              <br />
              Let our team of watch specialists guide you in finding the right preowned Royal Oak
              watch. Every secondhand Royal Oak watch for sale on Gray &amp; Sons’ website is in
              stock, ready to ship immediately. Plus, as an independent Audemars Piguet watch
              dealer, our decision-makers benefit from price flexibility and are ready to make a
              deal with you. Visit Gray and Sons Jewelers Website:{' '}
              <a href="/">www.grayandsons.com </a>
              <br />
              <br />
              Conversely, if you want to know, “Where can I sell my used Royal Oak watch near me?”
              we buy luxury watches too. Visit{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a> and contact our
              specialist Rich to see our special selling procedure - quick, easy, and free of
              charge. <a href="/chat-with-rich">Chat with a decision-maker here </a>
              to learn more about selling your secondhand AP Royal Oak to Gray &amp; Sons for the
              best cash offer.
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Royal Oak
                Watches{' '}
              </b>
              <br />
              <br />
              If you’re in the Miami area, visit the Gray &amp; Sons boutique, located near the
              upscale Bal Harbour Shops. We are open six days a week and our watch experts are ready
              to assist if you want to browse our large collection of secondhand Audemars Piguet
              Royal Oak watches for sale.
              <br />
              <br />
              Gray &amp; Sons has served thousands of happy customers over the last 40 years at both
              our physical boutique and top-tier pre-owned luxury watch e-commerce website. We’re
              proud to say that our clients have dubbed Gray &amp; Sons the number one seller and
              buyer of used Audemars Piguet Royal Oak watches.
              <br />
              <br />
              <br />
              <br />
              WEBSITE: <a href="/">www.grayandsons.com </a>
              &amp;
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              <br />
              CALL: 305-770-6955. <br />
              TEXT: Vika at 786-266-4763 for a quick response. <br />
              EMAIL: sell@grayandsons.com <br /> <br />
              Gray and Sons Jewelers specializes in Pre-Owned, Estate,
              <br />
              Vintage, Antique, Heirloom, Used Watches &anp; Jewelry
              <br />
              <br />
              We Buy, Sell Trade, Consign and Repair Used and Pre-Owned Watches and Jewelry
              <br />
              <br />
              Our customers say; "There are no better certified pre-owned Watch Specialists &amp;
              Jewelers than Gray and Sons Jewelers in all of Florida and the US"
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/audemars-piguet/">
                <button>SHOP AUDEMARS PIGUET ROYAL OAK WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK AUDEMARS PIGUET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <APCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default APRoyalOakMain
